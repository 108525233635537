<template>
  <b-row>
    <b-col cols="12">
      <b-card no-body class="mb-4 p-4">
        <div class="content-comunicado">
          <div
            v-if="!imagemComunicado"
            class="upload-box-comunicado"
            @dragover="dragover"
            @dragleave="dragleave"
            @drop="drop"
          >
            <img :src="upIcon" />
            <div class="uploat-text-content is-mobile">
              <strong>{{ $t("COMUNICADO.ARRASTE") }}</strong>
              <div class="upload-desc">.PNG, .JPG</div>
            </div>
            <strong v-on:click="fileUp" class="uploa-btn"> Selecionar Arquivo </strong>
          </div>
          <div
            v-else-if="imagemComunicado"
            :style="`background-image:url(${imagemComunicado})`"
            class="imagem-comunicado"
          >
            <b-button
              @click="fileUp"
              class="btn-comunicado-remover"
              variant="primary"
              >Trocar Imagem
            </b-button>
          </div>
          <input
            :accept="'.png, .jpg'"
            type="file"
            :id="'fileup-input-image'"
            @change="inputUpload"
            class="fileup-input"
            name=""
          />

          <form ref="anyName" class="infos-comunicado">
            <div class="div-datas">
              <input-text
                :label="$t('COMUNICADO.TITULO')"
                :placeholder="$t('COMUNICADO.TITULO')"
                v-model="form.titulo"
              />
              <div class="p-1 flex-fill">
                <b-form-group :label="$t('COMUNICADO.DE')">
                  <b-form-datepicker
                    placeholder="00/00/0000"
                    :date-format-options="{
                      year: 'numeric',
                      month: 'numeric',
                      day: 'numeric',
                    }"
                    locale="br"
                    v-model="form.dataInicio"
                  ></b-form-datepicker>
                </b-form-group>
              </div>
              <div class="p-1 flex-fill">
                <b-form-group :label="$t('COMUNICADO.ATE')">
                  <b-form-datepicker
                    placeholder="00/00/0000"
                    :date-format-options="{
                      year: 'numeric',
                      month: 'numeric',
                      day: 'numeric',
                    }"
                    locale="br"
                    v-model="form.dataExpiracao"
                  ></b-form-datepicker>
                </b-form-group>
              </div>
            </div>
            <input-text
              :label="$t('COMUNICADO.LINK')"
              :placeholder="$t('COMUNICADO.LINK')"
              v-model="form.link"
            />
            <div
              v-if="!id"
              class="upload-box-cnpj"
              @dragover="dragover"
              @dragleave="dragleave"
              @drop="drop"
            >
              <img :src="upIcon" />
              <div class="nomeArquivoCsv" v-if="nomeArquivoCsv">
                Arquivo Importado: {{ nomeArquivoCsv }}
              </div>
              <div v-else-if="!nomeArquivoCsv" class="uploat-text-content is-mobile">
                <strong>{{ $t("COMUNICADO.ARRASTE_CNPJ") }}</strong>
                <div class="upload-desc">.CSV</div>
              </div>
              <strong v-on:click="fileUpCsv" class="uploa-btn">
                {{ nomeArquivoCsv ? "Trocar Arquivo" : "Selecionar Arquivo" }}
              </strong>
              <input
                :accept="'.csv'"
                type="file"
                :id="'fileup-input-csv'"
                @change="inputUploadCsv"
                class="fileup-input"
                name=""
              />
            </div>
            <b-button
              v-if="!id && permissao.criar"
              class="btn-comunicado"
              @click="criarComunicado"
              variant="primary"
              >
              Criar Comunicado
            </b-button>
            <b-button
            v-if="id && permissao.editar"
            class="btn-comunicado"
            @click="editarComunicado"
              variant="primary"
            >
              Editar Comunicado
            </b-button>
            <b-button
              class="btn-comunicado"
              @click="cancelarComunicado"
              variant="secondary"
            >
              Cancelar
            </b-button>
          </form>
        </div>
      </b-card>
      <b-card v-if="this.id" no-body class="mb-4 p-4">
        <div class="header-table-operacoes">
          <b-form-group
            label="Por Pagina"
            label-for="per-page-select"
            label-class="fw-medium"
            class="por-pagina"
          >
            <b-form-select
              id="per-page-select"
              v-model="perPage"
              @change="setPorPagina"
              :options="pageOptions"
            >
            </b-form-select>
          </b-form-group>
          <b-pagination
            @change="setPage"
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
          >
          </b-pagination>
        </div>

        <div class="tabela-usuarios">
          <b-table head-variant="light" hover :items="items" :fields="campos">
            <template v-slot:cell(acoes)="item">
              <div class="flex">
                <b-dropdown variant="outline-default">
                  <b-dropdown-item @click="abrirVizualizar(item.item.id)">{{
                    $t("GERAL.VER")
                  }}</b-dropdown-item>
                  <b-dropdown-item @click="excluir(item.item)">{{
                    $t("GERAL.EXCLUIR")
                  }}</b-dropdown-item>
                </b-dropdown>
              </div>
            </template>
          </b-table>
        </div>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import ComunicadoService from "@/common/services/comunicado/comunicado.service";
import { START_LOADING, STOP_LOADING } from "@/store/Store";
import { DEFINIR_BREADCRUMB } from "@/store/breadcrumbs.module";
import InputText from "@/components/inputs/InputText";
import helpers from "@/common/utils/helpers";

export default {
  name: "CriarComunicado",
  components: {
    InputText,
  },
  data() {
    return {
      id: this.$route.params.id,
      upIcon: require("./upload.svg"),
      removeIcon: require("./remove.svg"),
      imagemComunicado: undefined,
      nomeArquivoCsv: undefined,
      campos: [
        {
          key: "cnpj",
          label: this.$t("COMUNICADO.CNPJ"),
          sortable: true,
          formatter: (value) => {
            if (value) return helpers.maskCnpj(value);
          },
        },
        {
          key: "nomeEmpresa",
          label: this.$t("COMUNICADO.NOME_EMPRESA"),
          sortable: true,
        },
      ],
      items: [],
      form: {},
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [5, 10, 15, { value: 999, text: this.$t("GERAL.PAGINACAO") }],
    };
  },
  mounted() {
    if (this.id) {
      this.$store.dispatch(DEFINIR_BREADCRUMB, [
        { titulo: this.$t("MENU.COMUNICADO") },
        { titulo: this.$t("COMUNICADO.EDITAR_COMUNICADO") },
      ]);

      this.buscarComunicado();
      this.listarCnpj();
    }
    else {
      this.$store.dispatch(DEFINIR_BREADCRUMB, [
        { titulo: this.$t("MENU.COMUNICADO") },
        { titulo: this.$t("COMUNICADO.CRIAR_COMUNICADO") },
      ]);
    }
  },
  methods: {
    cancelarComunicado() {
      this.$router.push({ name: "comunicado" });
    },
    setPage(e) {
      this.listarCnpj(e);
    },
    setPorPagina(e) {
      this.listarCnpj(undefined, e);
    },
    listarCnpj(paginaAtual, porPagina) {
      this.$store.dispatch(START_LOADING);

      let sendForm = {};
      sendForm.id = this.id;
      sendForm.TamanhoDaPagina = porPagina || this.perPage;
      sendForm.NumeroDaPagina = (paginaAtual || this.currentPage) - 1;

      ComunicadoService.listarCnpj(sendForm)
        .then((result) => {
          this.items = result.data.data.itens;

          this.perPage = porPagina || this.perPage;
          this.currentPage = paginaAtual || this.currentPage;
          this.totalRows = result.data.data.paginacao.totalDeElementos;
        })
        .catch(() => {})
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    inputUpload(event) {
      this.drop(event);
      event.target.value = "";
    },
    inputUploadCsv(event) {
      this.dropCsv(event);
      event.target.value = "";
    },
    dropCsv(event) {
      this.arquivoCsv = event.target.files[0];
      this.nomeArquivoCsv = event.target.files[0].name;
    },
    dragover(event) {
      event.preventDefault();
    },
    dragleave(event) {
      event.preventDefault();
    },
    async drop(event) {
      let target = event.target;
      if (target.files && target.files.length) {
        try {
          const uploadedImageBase64 = await this.convertFileToBase64(target.files[0]);
          //console.log("uploadedImageBase64", uploadedImageBase64);
          this.imagemComunicado = uploadedImageBase64;
          //do something with above data string
        } catch (err) {
          //handle error
        }
      }
      event.preventDefault();
    },
    convertFileToBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        // Typescript users: use following line
        // reader.onload = () => resolve(reader.result as string);
        reader.onerror = reject;
      });
    },
    fileUp() {
      document.getElementById("fileup-input-image").click();
    },
    fileUpCsv() {
      document.getElementById("fileup-input-csv").click();
    },
    buscarComunicado() {
      this.$store.dispatch(START_LOADING);

      ComunicadoService.buscarComunicado(this.id)
        .then((result) => {
          let data = result.data.data;
          this.form = data;
          this.imagemComunicado = data.fotoBase64;
        })
        .catch(() => {})
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    criarComunicado() {
      this.$store.dispatch(START_LOADING);
      let formData = new FormData();

      for (var key in this.form) {
        formData.append(key, this.form[key]);
      }

      formData.append("FotoBase64", this.imagemComunicado);
      formData.append("ArquivoCsv", this.arquivoCsv);

      ComunicadoService.criar(formData)
        .then((result) => {
          if (result) {
            this.$router.push({ name: "comunicado" });
          }
        })
        .catch(() => {})
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    editarComunicado() {
      this.$store.dispatch(START_LOADING);
      let form = this.form;
      form.FotoBase64 = this.imagemComunicado;
      
      ComunicadoService.editar(form)
        .then((result) => {
          if (result) {
            this.$router.push({ name: "comunicado" });
          }
        })
        .catch(() => {})
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
  },
  computed: {
    permissao: function () {
      return helpers.validarAcesso("Comunicado");
    },
  },
};
</script>

<style>
.content-comunicado {
  display: flex;
  justify-content: center;
  align-items: self-start;
}

.upload-box-comunicado {
  width: 295px;
  height: 395px;
  border: 2px dashed #ced4da;
  background: #f8f9fa;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  display: grid;
}

.imagem-comunicado {
  width: 295px;
  height: 395px;
  border-radius: 10px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  cursor: pointer;
  transition: 0.5s;
  position: relative;
}

.nomeArquivoCsv {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 300px;
}

.imagem-comunicado:hover {
  transform: scale(1.1);
}

.upload-box-cnpj {
  margin-top: 10px;
  width: 100%;
  height: 80px;
  border: 2px dashed #ced4da;
  background: #f8f9fa;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-comunicado {
  width: 48%;
  margin: auto;
  margin-top: 15px;
  margin-right: 2%;
}

.infos-comunicado {
  margin-left: 15px;
}

.div-datas {
  display: flex;
  align-items: center;
}

.btn-comunicado-remover {
  position: absolute;
  left: 0;
  right: 0;
  width: 50%;
  margin: auto;
  bottom: 15px;
}

img {
  margin-right: 10px;
  margin: auto;
}

strong {
  font-weight: 600;
}

.upload-desc {
  color: #adb5bd;
  margin: auto;
}

.uploat-text-content {
  text-align: center;
  margin: auto;
}

.upload-divider {
  width: 1px;
  height: 30px;
  background-color: #dee2e6;
  margin: 0 25px;
}

.upload-box img {
  margin-right: 10px;
  margin-left: 10px;
  margin: auto;
}

.uploa-btn {
  background: #ffffff;
  border: 1px solid #e9ecef;
  box-sizing: border-box;
  border-radius: 3px;
  padding: 10px;
  cursor: pointer;
  transition: 0.5s;
  text-align: center;
  margin-right: 10px;
  margin: auto;
}

.uploa-btn:hover {
  transform: scale(1.1);
}

.fileup-input {
  display: none;
}
</style>
